/*************************************************************
** Default                                                 **
** Inherits from 320px standard                            **
** Media query checks for CSS3 support. Has touch support. **
*************************************************************/

@media #{$mq-default} {

    .info__block {
        padding: 20% 8% 8% 7%;
    }

    .info__title,
    .info__sub-block--title {
        color: $white;
        font-family: $open-sans;
        font-weight: 300;
        letter-spacing: 1px;
    }

    .info__sub-block {
        margin-bottom: 13%;
    }

    .info__exhibits-block {
        margin: 15% 0;
    }

    .info__title {
        color: $white;
        font-size: 6vw;
        margin: 0;
    }

    .info__sub-block--title {
        font-size: 4vw;
    }

    .info__sub-block--body {
        color: $white;
        font-family: $open-sans;
        font-size: 3.3vw;
        line-height: 210%;

        a {
            color: $white;
            font-family: $inconsolata;
            text-decoration: none;
            text-transform: uppercase;
            font-size: 3vw;
            letter-spacing: 4px;
        }

        a:after {
            position: relative;
            display: inline-block;
            content: '∟';
            color: $white;
            font-size: 4vw;
            transform: scale(-1) rotate(45deg) translateZ(0);
            vertical-align: middle;
            margin-left: 10px;
        }
    }

    .info__table {
        color: $white;
        width: 100%;
        border-collapse: collapse;
    }

    .info__table-block {
        border-bottom: 1px solid $white;
        width: 100%;
    }

    .info__table-block:last-of-type {
        border: none;
    }

    .info__table-block--date,
    .info__table-block--where {
        color: $white;
        font-family: $open-sans;
        font-weight: 300;
        display: block;
    }

    .info__table-block--date {
        font-size: 5vw;
        padding: 35px 0 30px 0;
        letter-spacing: 2px;
    }

    .info__table-block--where {
        font-size: 5vw;

        span {
            display: block;
            padding: 5px 0 20px 0;
            font-size: 3vw;
            font-weight: 400;
            letter-spacing: 1px;
        }
    }

    .info__table-block--more {
        color: $white;
        font-family: $inconsolata;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 3vw;
        letter-spacing: 4px;
        padding: 0 15px 8px 0;
        display: inline-block;
    }

    .info__newletter--about {
        color: $white;
        font-family: $open-sans;
        font-size: 3vw;
        line-height: 240%;
    }

    .info__newsletter--form {
        padding-top: 15px;

        input[type=text] {
            width: 49%;
            margin-top: 0;
        }
    }

    .info__newsletter--form-submit {
        font-family: $inconsolata;
        color: $white;
        background-color: $black;
        border: none;
        font-size: 3vw;
        letter-spacing: 4px;
        text-transform: uppercase;
        margin-left: 65%;
        cursor: pointer;
        border-radius: 0;
        appearance: none;
    }

    .info__footer-image {
        width: 85%;
        display: block;
        margin: 8% auto 10% auto;
    }
} // end $default


/*******************************************************
** 375px - Ideal for iPhone Landscape, other mobiles **
** Inherits from 320px Standard, and 320px Enhanced  **
** Has touch support                                 **
*******************************************************/

@media #{$mq-small} {

    .info__table-block--date {
        font-size: 4vw;
    }

    .info__newsletter--form {

        input[type=text] {
            margin-top: -4px;
        }
    }

    .info__newsletter--form-submit {
        margin-left: 65%;
    }

} // end $small


/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .info__block {
        padding: 13% 8% 8% 7%;
    }

    .info__sub-block {
        margin: 4% 0;
    }

    .info__title {
        font-size: 3.5vw;
    }

    .info__sub-block--title {
        font-size: 2vw;
        display: inline-block;
        vertical-align: top;
        width: 15%;
        margin: 0;
    }

    .info__sub-block--body {
        font-size: 1.5vw;
        line-height: 285%;
        display: inline-block;
        width: 75%;
        margin-bottom: 3%;

        p {
            margin: 0 0 0 9%;
        }

        p:nth-of-type(2){
            margin-top: 3%;
        }

        a, a:after {
            font-size: 1.5vw;
        }
    }

    .info__exhibits-block {
        margin: 5% 0 6% 0;
    }

    .info__table-block {
        display: block;
        padding: 15px 0 15px 0;
    }

    .info__table-block--date,
    .info__table-block--where {
        display: inline-block;
    }

    .info__table-block--date {
        font-size: 2vw;
        padding: 0;
        width: 32%;
        vertical-align: top;
    }

    .info__table-block--where {
        font-size: 2vw;
        width: 35%;

        span {
            font-size: 1.5vw;
            padding: 11px 0 9px 0;
        }
    }

    .info__table-block--more {
        font-size: 1.5vw;
        display: inline-block;
        text-align: right;
        width: 30%;
        padding: 0;
        vertical-align: top;

        a {
            width: 100%;
        }
    }

    .info__newsletter--form {
        input[type=text] {
            width: 48%;
            margin-top: -4px;
        }
    }

    .info__newletter--about {
        font-size: 1.7vw;
        margin: 30px 0 7px 0;
    }

    .info__newsletter--form-submit {
        font-size: 1.5vw;
        margin-left: 60%;
    }

    .info__footer-image {
        width: 87%;
        margin: 3% auto 10% auto;
    }

} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .info__block {
        padding: 8% 10% 0 10%;
    }

    .info__sub-block {
        margin: 4% 0;
    }

    .info__title {
        font-size: 3vw;
    }

    .info__sub-block--title {
        font-size: 2vw;
        width: 19%;
    }

    .info__sub-block--body {
        font-size: 1.4vw;
        width: 60%;
        line-height: 250%;
        margin: 0;

        a, a:after  {
            font-size: 1.3vw;
        }

        p:nth-of-type(2){
            margin-top: 0;
        }
    }

    .info__exhibits-block {
        margin: 8% 0 6% 0;
    }

    .info__table {
        width: 84%;
    }

    .info__table-block {
        padding: 30px 0 15px 0;
    }

    .info__table-block--date {
        font-size: 1.7vw;
        width: 29%;
    }

    .info__table-block--where {
        font-size: 1.7vw;

        span {
            font-size: 1.4vw;
            padding: 4px 0 9px 0;
        }
    }

    .info__table-block--more {
        font-size: 1vw;
        width: 33%;
    }

    .info__newsletter--form {
        padding-top: 46px;

        input[type=text] {
            width: 24%;
            margin-top: -11px;
        }
    }

    .info__newletter--about {
        font-size: 1.4vw;
        width: 85%;
    }

    .info__newsletter--form-submit {
        font-size: 1vw;
        margin-left: 34%;
    }

    .info__footer-image {
        width: 80%;
        margin: 9% auto;
    }

} // end $large


/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    .info__title {
        font-size: 2vw;
    }

    .info__sub-block--title {
        font-size: 1.5vw;
    }

    .info__sub-block--body {
        font-size: 1vw;

        a, a:after  {
            font-size: .8vw;
        }
    }

    .info__table-block {
        padding: 45px 0 25px 0;
    }

    .info__table-block--date {
        font-size: 1.5vw;
    }

    .info__table-block--where {
        font-size: 1.5vw;

        span {
            font-size: 1vw;
        }
    }

    .info__table-block--more {
        font-size: .8vw;
    }

    .info__newletter--about {
        font-size: 1vw;
    }

    .info__newsletter--form {

        input[type=text] {
            margin-top: -5px;
        }
    }

    .info__newsletter--form-email {
        width: 30%;
    }

    .info__newsletter--form-submit {
        font-size: .8vw;
        margin-left: 34%;
    }

} // end $largest


/***************************************************
** Retina x2 - Should be used to replace images. **
** Inherits nothing.                             **
***************************************************/

@media #{$mq-retina} {}
