body {
    background-color: $black;
    position: relative;
    margin: 0;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-font-smoothing: antialiased;
}

.full-screen {
    width: 100vw;
    height: 100vh;
}

.no-scroll {
    overflow: hidden;
}

.sa-arrow {
    font-family: $inconsolata;
    color: $white;
    position: relative;
    display: inline-block;
    font-size: 3.5vw;
    transform: scale(-1) rotate(45deg) translateZ(0);
    backface-visibility: hidden;
    text-shadow: -.1px -.1px 0 $white, -.1px .1px 0 $white, .1px -.1px 0 $white, .1px .1px 0 $white, 0px -.1px 0 $white, .1px 0px 0 $white;
}

input[type=text] {
    color: $white;
    font-family: $open-sans;
    text-transform: uppercase;
    font-size: 2.5vw;
    letter-spacing: 1.5px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $white;
    background-color: inherit;
    position: absolute;
}

input[type=text]:focus {
  outline: none;
}

/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .sa-arrow {
        font-size: 1.7vw;
    }

    input[type=text] {
        font-size: 1.5vw;
    }

} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .sa-arrow {
        font-size: 1.2vw;
    }

} // end $large



/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    input[type=text] {
        font-size: .8vw;
    }

    .sa-arrow {
        font-size: .8vw;
    }

} // end $largest
