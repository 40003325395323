/*************************************************************
** Default                                                 **
** Inherits from 320px standard                            **
** Media query checks for CSS3 support. Has touch support. **
*************************************************************/

@media #{$mq-default} {

    .sa__main-nav {
        padding: 25px;
        background-color: $black;
        overflow-y: scroll;
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 100;
    }

    .sa__main-nav--closed {
        display: none;
    }

    .sa-modal__social {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .sa-modal-nav__links {
        font-family: $inconsolata;
        color: $white;
        font-size: 2.3vw;
        line-height: 250%;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 5px;
    }

    .sa-modal__main-nav {
        list-style: none;
        position: absolute;
        top: 27%;
        width: 70%;
    }

    .sa-modal__main-nav--links {
        font-family: $open-sans;
        font-weight: 300;
        color: $white;
        font-size: 7vw;
        letter-spacing: 1px;
        line-height: 207%;
        text-decoration: none;
        display: block;
    }

    .sa-modal__newsletter {
        position: absolute;
        top: 60%;
    }

    .sa-modal__newsletter--title {
        font-family: $open-sans;
        font-weight: 300;
        font-size: 6vw;
        letter-spacing: .8px;
        color: $white;
        margin: 0;
    }

    .sa-modal__newsletter--about {
        font-family: $open-sans;
        color: $white;
        font-size: 3vw;
        letter-spacing: .5px;
        line-height: 240%;
        padding-bottom: 3px;
        width: 90%;
        margin: 0 0 0 1px;
    }

    .sa-modal__newsletter--form {
        margin: 0 0 0 1px;

        input[type=text] {
            width: 55%;
            margin-top: -2px;
        }
    }

    .sa-modal__newsletter--form-submit {
        font-family: $inconsolata;
        color: $white;
        background-color: $black;
        border: none;
        font-size: 3vw;
        letter-spacing: 4px;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin: 0 0 5px 61%;
        cursor: pointer;
        border-radius: 0;
        appearance: none;
    }

    .sa-modal__email {
        font-family: $inconsolata;
        color: $white;
        font-size: 2.5vw;
        letter-spacing: 5px;
        text-transform: uppercase;
        transform: rotate(90deg) translateY(-48px);
        position: absolute;
        right: 10px;
        top: 38%;
    }
} // end $default



/*******************************************************
** Default max-height 479px - Ideal for mobile        **
** landscape                                          **
*******************************************************/
@media #{$mq-default} and (max-height: 479px) {

    .sa-modal__main-nav {
        margin-top: 4%;
    }

    .sa-modal__main-nav--links {
        font-size: 5vw;
    }

    .sa-modal__newsletter {
        margin-top: 25%;
    }

    .sa-modal__newsletter--about {
        width: 90%;
    }

    .sa-modal__newsletter--title {
        font-size: 5vw;
    }

    .sa-modal__newsletter--form {
        padding-bottom: 50px;
    }

    .sa-modal__email {
        margin-top: 10%;
    }

} // end $default max-height 479



/*******************************************************
** 375px - Ideal for iPhone Landscape, other mobiles **
** Inherits from 320px Standard, and 320px Enhanced  **
** Has touch support                                 **
*******************************************************/

@media #{$mq-small} {

    .sa-modal-nav__links {
        font-size: 2.5vw;
    }

    .sa-modal__main-nav {
        top: 33%;
    }

    .sa-modal__newsletter {
        top: 64%;
    }

    .sa-modal__newsletter--about {
        padding-bottom: 15px;
    }

    .sa-modal__email {
        top: 44%;
    }

    .sa-modal__newsletter--form input[type=text] {
        width: 61%;
    }

    .sa-modal__newsletter--form-submit {
        padding-left: 23px;
    }


} // end $small




/*******************************************************
** Small-Plus max-height 1100px - Ideal for mobile    **
** landscape                                          **
*******************************************************/
@media #{$mq-small-plus} and (max-height: 1100px) {
    .sa-modal__main-nav {
        margin-top: 20%;
        top: 0;
    }

    .sa-modal__main-nav--links {
        font-size: 5vw;
    }

    .sa-modal__newsletter {
        margin-top: 55%;
        top: 0;
    }

    .sa-modal__newsletter--form {
        input[type=text] {
            font-size: 1.5vw;
        }
    }

    .sa-modal__email {
        margin-top: 35%;
        top: 0;
    }
} // end $small-plus max-height 1100




/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .sa__main-nav {
        padding: 46px 50px;
    }

    .sa-modal-nav__links {
        font-size: 1.5vw;
    }

    .sa-modal__main-nav {
        margin: 0;
        top: 47%;
    }

    .sa-modal__main-nav--links {
        font-size: 4vw;
        display: inline-block;
        margin-right: 45px;
    }

    .sa-modal__newsletter {
        margin: 0;
        top: 67%;
    }

    .sa-modal__newsletter--title {
        font-size: 3.7vw;
    }

    .sa-modal__newsletter--about {
        font-size: 1.8vw;
        width: 83%;
        margin: 27px 0 0 0;
        padding-bottom: 25px;
    }

    .sa-modal__newsletter--form {
        padding-bottom: 50px;
    }

    .sa-modal__newsletter--form-submit {
        font-size: 1.5vw;
        padding-left: 50px;
        margin: 0 0 5px 43%;
        vertical-align: middle;
    }

    .sa-modal__email {
        font-size: 1.2vw;
        transform: rotate(90deg) translateY(-12px);
        margin: 0;
        top: 50%;
    }

    .sa-modal__newsletter--form input[type=text] {
        width: 44%;
        margin-top: -6px;
    }

} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .sa__main-nav {
        padding: 40px;
    }

    .sa-modal-nav__links {
        font-size: 1.2vw;
    }

    .sa-modal__main-nav {
        margin: 0 0 0 6.5%;
        top: 45%;
        width: 75%;
    }

    .sa-modal__main-nav--links {
        margin-right: 75px;
    }

    .sa-modal__newsletter {
        margin: 0 0 0 6.5%;
        top: 63.5%;
    }

    .sa-modal__newsletter--title {
        font-size: 2vw;
    }

    .sa-modal__newsletter--about {
        font-size: 1.2vw;
        margin-top: 14px;
    }

    .sa-modal__newsletter--form-submit {
        margin: 0 0 5px 28%;
        font-size: 1vw;
    }

    .sa-modal__email {
        transform: rotate(90deg) translateY(-21px);
        right: 0;
    }

    .sa-modal__newsletter--form input[type=text] {
        width: 30%;
        margin-top: -11px;
    }
} // end $large


/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    .sa-modal-nav__links {
        font-size: .8vw;
    }

    .sa-modal__main-nav--links {
        font-size: 3vw;
    }

    .sa-modal__newsletter--about {
        font-size: 1vw;
    }

    .sa-modal__newsletter--form-submit {
        font-size: .8vw;
    }

    .sa-modal__email {
        font-size: .8vw;
    }

    .sa-modal__newsletter--form input[type=text] {
        margin-top: -5px;
    }

} // end $largest


/***************************************************
** Retina x2 - Should be used to replace images. **
** Inherits nothing.                             **
***************************************************/

@media #{$mq-retina} {}
