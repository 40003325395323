.sa-override-cursor {
    cursor: none;
}

.sa-cursor {
    position: absolute;
    pointer-events: none;
    z-index: 2;
    top: -50px;
}

.sa-cursor__content {
    pointer-events: none;
}

.sa-cursor__text {
    color: $white;
    font-family: $inconsolata;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 5px;
}

.sa-cursor__arrow {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 1px solid $white;
    border-right: 1px solid $white;
}

.sa-cursor__arrow--right {
    transform: rotate(45deg);
}

.sa-cursor__arrow--left {
    transform: rotate(225deg);
}
