/*************************************************************
** Default                                                 **
** Inherits from 320px standard                            **
** Media query checks for CSS3 support. Has touch support. **
*************************************************************/

@media #{$mq-default} {
    .sa-work-block {
        width: 86%;
        display: block;
        margin: 0 auto 40px auto;
    }

    .sa-work-block:first-of-type {
        padding-top: 21%;
    }

    .work-body__link {
        text-decoration: none;
    }

    .sa-work-block__image {
        width: 100%;
    }

    .sa-work-block__caption {
        color: $white;
        font-family: $open-sans;
        font-size: 3.5vw;
        line-height: 170%;
        padding: 0 0 3px 0;
        letter-spacing: 2px;
        text-decoration: none;
    }

    .sa-work-block__link {
        color: $white;
        font-family: $inconsolata;
        font-size: 3vw;
        letter-spacing: 4px;
        margin: 0;
        text-transform: uppercase;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
    }
} // end $smallest


/*******************************************************
** 375px - Ideal for iPhone Landscape, other mobiles **
** Inherits from 320px Standard, and 320px Enhanced  **
** Has touch support                                 **
*******************************************************/

@media #{$mq-small} {


} // end $small


/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .work-body {
        margin: 0 30px 0 45px;
    }

    .sa-work-block {
        width: 49%;
        display: inline-block;
        margin: 0 auto 50px auto;
        vertical-align: top;
    }

    .sa-work-block:first-of-type, .sa-work-block:nth-of-type(2) {
        padding-top: 19%;
    }

    .sa-work-block__image {
        display: block;
        width: 95%;
        margin: 0 auto;
    }

    .sa-work-block__caption {
        font-size: 1.5vw;
        width: 94%;
        margin: 5px auto 0 auto;
    }

    .sa-work-block__link {
        display: block;
        font-size: 1.5vw;
        width: 94%;
        margin: 0 auto;
    }

} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .work-body {
        margin: 0 80px;
    }

    .sa-work-block {
        width: 32%;
        display: inline-block;
        margin: 0 5px 0 0;
        padding-bottom: 5%;
    }

    .sa-work-block:first-of-type, .sa-work-block:nth-of-type(2), .sa-work-block:nth-of-type(3) {
        padding-top: 9%;
    }

    .sa-work-block__caption {
        width: 94%;
        padding: 23px 0 5px 0;
        font-size: 1.3vw;
    }

    .sa-work-block__link {
        width: 94%;
        font-size: 1vw;
    }

} // end $large


/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    .work-body {
        margin: 0 112px;
    }

    .sa-work-block {
        margin: 0 9px 0 0;
    }

    .sa-work-block__caption {
        font-size: 1vw;
    }

    .sa-work-block__link {
        font-size: .8vw;
    }

} // end $largest


/***************************************************
** Retina x2 - Should be used to replace images. **
** Inherits nothing.                             **
***************************************************/

@media #{$mq-retina} {}
