/*************************************************************
** Default                                                 **
** Inherits from 320px standard                            **
** Media query checks for CSS3 support. Has touch support. **
*************************************************************/

@media #{$mq-default} {

    .sa-home__title-block {
        width: 100%;
    }

    .sa-home__title {
        display: none;
    }

    .sa-home-nav,
    .sa-home-social {
        display: none;
        z-index: 1;
    }

    .sa-home-nav {
        position: absolute;
        top: 0;
        text-decoration: none;
        margin: 0;
    }

    .sa-home-social {
        position: absolute;
        left: 0;
        bottom: 0;
        list-style: none;
    }

    .sa-home__header--email {
        color: $white;
        font-family: $open-sans;
        text-transform: uppercase;
        font-size: 3vw;
        letter-spacing: 4px;
        padding: 25px 0 0 25px;
    }

    .sa__hamburger {
        width: 9.8vw;
        height: 8vw;
        position: absolute;
        right: 0;
        top: 0;
        margin: 30px 25px 25px 25px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 150;
    }

    .sa__line {
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: $white;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    .sa__line:nth-child(1) {
        top: 0px;
    }

    .sa__line:nth-child(2), .sa__line:nth-child(3) {
        top: 2.7vw;
    }

    .sa__line:nth-child(4) {
        top: 5.2vw;
    }

    .sa__hamburger--open {
        position: fixed;
    }

    .sa__hamburger--open .sa__line:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .sa__hamburger--open .sa__line:nth-child(2) {
        transform: rotate(45deg);
    }

    .sa__hamburger--open .sa__line:nth-child(3) {
        transform: rotate(-45deg);
    }

    .sa__hamburger--open .sa__line:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .home-slideshow {
        display: none;
    }

    .home-sideshow__img-wrap {
        display:flex;
        align-items:center;
        justify-content:center;
    }

    .home-slideshow__img {
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 80%;
        margin: 0;
        padding: 0;
    }

    .sa-home__mobile-image-block {
        display: 100%;
    }

    .sa-home__mobile-image {
        display: block;
        margin: 8% auto 0 auto;
        width: 78%;
    }

    .sa-home-nav__link,
    .sa-home__social-link,
    .sa-home__email {
        font-family: $inconsolata;
        color: $white;
        font-size: .8vw;
        line-height: 240%;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 6px;
    }

    .sa-home__email {
        display: none;
    }
} // end $smallest


/*******************************************************
** 375px - Ideal for iPhone Landscape, other mobiles **
** Inherits from 320px Standard, and 320px Enhanced  **
** Has touch support                                 **
*******************************************************/

@media #{$mq-small} {

    .sa-home__mobile-image {
        width: 88%;
    }

} // end $small


/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .sa-home__title {
        width: 90%;
        display: inline;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 10;
    }

    .sa-home__header--email {
        display: none;
    }

    .sa__hamburger {
      width: 5.8vw;
      margin: 6.5vw 6.5vw 0 0;
    }

    .sa__line:nth-child(2), .sa__line:nth-child(3) {
      top: 1.7vw;
    }

    .sa__line:nth-child(4) {
      top: 3.3vw;
    }

    .sa-home__mobile-image {
        display: none;
    }

    .home-slideshow {
        display: block;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    .home-slideshow__img {
        max-width: 75%;
        max-height: 75%;
    }

    .sa-home__email {
        display: none;
    }
} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .sa__hamburger,
    .sa-mobile-nav__email,
    .sa-mobile-nav__social,
    .sa-mobile-nav__links,
    .sa-mobile-nav__social-link {
        display: none;
    }

    .sa-work__hamburger {
        width: 3.6vw;
        margin: 2.5vw 2.5vw 0 0;
    }

    .sa__line:nth-child(2), .sa__line:nth-child(3) {
      top: 1vw;
    }

    .sa__line:nth-child(4) {
      top: 2vw;
    }

    .sa-home-nav,
    .sa-home-social {
        display: inline-block;
    }

    .sa-home-nav {
        width: 100%;
        padding: 0;
    }

    .sa-home-nav {
        position: absolute;
    }

    .sa-home-nav__item {
        float:left;
        padding: 40px;
    }

    .sa-home-nav__item:last-of-type {
        position: absolute;
        right: 0;
    }

    .sa-home__footer {
        position: absolute;
        right: 0;
        bottom: 2%;
        left: 0;
        padding: 1rem;
    }

    .sa-home-social {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0 0 0 40px;
    }

    .sa-home-nav__link,
    .sa-home__social-link,
    .sa-home__email {
        font-size: 1.2vw;
        line-height: 240%;
        letter-spacing: 6px;
        padding: 20px;
    }

    .sa-home__email {
        display: inline;
        position: absolute;
        right: 3%;
        bottom: 1%;
        text-align: right;
    }

} // end $large


/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    .sa-home__title {
        width: 77%;
    }

    .sa-home-social {
        left: 0;
        bottom: 0;
    }

    .sa-home-nav__link,
    .sa-home__social-link,
    .sa-home__email {
        font-size: .8vw;
        line-height: 240%;
        letter-spacing: 6px;
    }
} // end $largest


/***************************************************
** Retina x2 - Should be used to replace images. **
** Inherits nothing.                             **
***************************************************/

@media #{$mq-retina} {}
