/*************************************************************
** Default                                                 **
** Inherits from 320px standard                            **
** Media query checks for CSS3 support. Has touch support. **
*************************************************************/

@media #{$mq-default} {

    .work-detail {
        color: $white;
        padding: 25px;
    }

    .work-detail--black {
        color: $black;
    }

    .work-detail__detail--link,
    .work-detail__expand--link {
        width: 2.7vw;
        height: 1.5vw;
        cursor: pointer;
        z-index: 150;
    }

    .work-detail__plus-line {
        display: block;
        position: absolute;
        height: 1px;
        width: 3vw;
        background: $white;
        transition: .25s ease-in-out;
    }

    .work-detail__plus-line:nth-child(1) {
        transform: rotate(90deg);
    }

    .work-detail__plus-line:nth-child(2) {
        transform: rotate(0deg);
    }

    .work-detail__plus--open .work-detail__plus-line:first-child {
        transform: rotate(-5deg);
    }

    //for the icons beside 'Details'
    .collapse-icon {
        visibility: hidden;
        position: absolute;
        width: 30px;
        z-index: 100;
    }

    .expand-icon__block,
    .collapse-icon__block {
        position: absolute;
    }

    .work-detail__header--title-expand {
        padding-top: 7px;
    }

    .exp-icon__row {
        display: block;
        margin: 0;
        padding: 0;
        height: .3vw;
        clear: both;
    }

    .exp-icon__cell {
        display: block;
        float: left;
        margin: 0;
        width: .3vw;
        height: .3vw;
    }

    .exp-icon__cell--top {
       border-top: 1px solid;
    }

    .exp-icon__cell--bottom {
        border-bottom: 1px solid;
    }

    .exp-icon__cell--left {
        border-left: 1px solid;
    }

    .exp-icon__cell--right {
        border-right: 1px solid;
    }

    .work-details__expand--open {
        .expand-icon {
            display: none;
        }
        .collapse-icon {
            visibility: visible;
        }
    }
    //end icons

    .work-detail__header {
        padding: 15px 0 0 0;
    }

    .work-detail__header--title {
        font-family: $inconsolata;
        color: inherit;
        font-size: 2.3vw;
        line-height: 20%;
        text-decoration: none;
        text-transform: uppercase;
        vertical-align: middle;
        letter-spacing: 4px;
        padding-left: 235%;
        margin: 0;
        padding: 0 0 0 30px;
    }

    .work-detail__expand-block {
        display: none;
    }

    .work-detail__x {
        width: 9.8vw;
        height: 8vw;
        position: absolute;
        right: 6vw;
        padding-top: 35px;
        top: .5vw;
        cursor: pointer;
        z-index: 150;
    }

    .work-detail__line {
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background: $white;
    }

    .work-detail__line--black {
        background: $black;
    }

    .work-detail__line:nth-child(1) {
        transform: rotate(45deg);
    }

    .work-detail__line:nth-child(2) {
        transform: rotate(-45deg);
    }

    .work-detail__image {
        position: absolute;
        top: -50px;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 0;
    }

    .work-details__expand--closed {
        width: 100%;
        .work-detail__image {
            top: 53%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: auto;
            height: auto;
            max-width: 65%;
            max-height: 70%;
            margin: 0;
            padding: 0;
        }
    }

    .work-detail__painting-details {
        padding: 20% 0 0 0;
        background-color: $black;
        overflow: hidden;
        position: fixed;
        width: 93%;
        height: 100%;
        z-index: 100;
    }

    .work-details__details--closed {
        display: none;
    }

    .work-details__details--hidden {
        visibility: hidden;
    }

    .work-detail__title {
        color: inherit;
        font-family: $inconsolata;
        text-transform: uppercase;
        font-size: 2.7vw;
        line-height: 300%;
        letter-spacing: 4px;
        display: block;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .work-detail__info {
        color: $white;
        font-family: $open-sans;
        font-size: 2.8vw;
        letter-spacing: 1px;
        display: block;
        text-align: center;
        margin: 0;
        padding: 0 5% 13% 5%;
    }

    .work-detail__previous,
    .work-detail__next {
        color: $white;
        font-size: 6vw;
        text-decoration: none;
        position: absolute;
        cursor: pointer;
    }

    .work-detail__previous {
        display: inline-block;
        transform: scale(1) rotate(45deg) translateZ(0);
        bottom: 49%;
        left: 8%;
    }

    .work-detail__next {
        display: inline-block;
        transform: scale(-1) rotate(45deg) translateZ(0);
        bottom: 49%;
        right: 8%;
    }
} // end $smallest


/*******************************************************
** 375px - Ideal for iPhone Landscape, other mobiles **
** Inherits from 320px Standard, and 320px Enhanced  **
** Has touch support                                 **
*******************************************************/

@media #{$mq-small} {

    .work-details__expand--closed {
        .work-detail__image {
            top: 50%;
        }
    }

    .work-details__expand--closed {
        .work-detail__image {
            max-width: 70%;
        }
    }

    .work-detail__painting-details {
        padding: 29% 0 0 0;
    }

    .work-detail__previous {
        left: 7%;
    }

    .work-detail__next {
        right: 7%;
    }

} // end $small


/*************************************************************
** 768px - Ideal for iPad Portrait                         **
** Inherits from 320px Standard, 320px Enhanced, and 480px **
** Has touch support.                                      **
*************************************************************/

@media #{$mq-medium} {

    .work-detail {
        padding: 0; //TODO come back and use this to determine the border for this page
    }

    .work-detail__detail--link {
        width: 1.5vw;
    }

    .work-detail__plus-line {
        width: 1.5vw;
    }

    .work-detail__header {
        padding: 60px 0 0 50px;
    }

    .work-detail__header--title {
        font-size: 1.5vw;
        padding-left: 29px;
    }

    .work-detail__header--title-expand {
        padding-top: 6px;
    }

    .work-detail__x {
        width: 5.8vw;
        padding-top: 58px;
        height: 7vw;
        right: 6.5vw;
    }

    .work-details__expand--closed {
        .work-detail__image {
            max-width: 70%;
        }
    }

    .work-detail__painting-details {
        padding: 20% 0 0 0;
        width: 100%;
    }

    .work-detail__title {
        font-size: 1.5vw;
    }

    .work-detail__info {
        font-size: 1.7vw;
    }

    .work-detail__painting-details {
        padding: 36% 0 0 0;
    }

    .work-detail__info {
        font-size: 1.5vw;
        padding: 0 0 5.5% 0;
    }

    .work-detail__previous,
    .work-detail__next {
        font-size: 3vw;
    }

    .work-detail__next {
        right: 7%;
    }

    .work-detail__previous {
        left: 7%;
    }
} // end $medium


/************************************************************
** 960px - Ideal for most browser screens, iPad Landscape **
** Inherits all the things.                               **
** Has touch support.                                     **
************************************************************/

@media #{$mq-large} {

    .sa-work__hamburger {
        display: inline;
    }

    .work-detail__details-block {
        padding-bottom: 0;
    }

    .work-detail__detail--link {
        width: 1.2vw;
    }

    .work-detail__plus-line {
        width: .8vw;
    }

    .work-detail__x {
        width: 3.6vw;
        right: 40px;
        padding-top: 40px;
    }

    .work-detail__header {
        padding: 40px 0 0 40px;
    }

    .work-detail__header--title {
        font-size: 1.2vw;
    }

    .work-detail__expand-block {
        display: inline;
        cursor: pointer;
    }

    .work-detail__expand-block--hidden {
        display: none;
    }

    .work-detail__title {
        font-size: 1.2vw;
    }

    .work-detail__info {
        font-size: 1.5vw;
        padding: 0 0 6% 0;
    }

    .work-detail__prev-next-buttons {
        display: none;
    }

    .is-touch .work-detail__prev-next-buttons {
        display: block;
    }

} // end $large


/**********************************************
** 1160px - Ideal for wider browser screens **
** Inherits all the things.                 **
** Has touch support.                       **
**********************************************/

@media #{$mq-largest} {

    .work-detail__detail--link {
        width: .8vw;
        top: 5px;
    }

    .work-detail__header--title {
        font-size: .8vw;
        padding-left: 40px;
    }

    .exp-icon__row {
        height: .25vw;
    }

    .exp-icon__cell {
        width: .25vw;
        height: .25vw;
    }

    .work-detail__x {
        // width: 5vw;
        // padding-top: 60px;
    }

    .work-detail__painting-details {
        padding: 12% 0 0 0;
    }

    .work-detail__title {
        font-size: .8vw;
    }

    .work-detail__info {
        font-size: 1vw;
        padding: 0 0 5% 0;
    }

} // end $largest


/***************************************************
** Retina x2 - Should be used to replace images. **
** Inherits nothing.                             **
***************************************************/

@media #{$mq-retina} {}
