/**
 * Open Sans
 *      400 - regular - default
 *      300 - light
 */
$open-sans: 'Open Sans', sans-serif;

/**
 * Inconsolata
 *      400 - regular - default
 */
$inconsolata: 'Inconsolata', monospace;
